<template>
    <div>
        <div class="sign-in-box box p-6">
            <h1 class="title is-5 mb-1 has-text-centered">Meghívó</h1>
            <h2 class="title is-7 mb-5 has-text-centered">a Sportcsarnok rendszer<br>használatához</h2>

            <div class="loader loader--centered" v-if="isLoading"></div>

            <Transition name="slide-left" mode="out-in">
                <div v-if="isState('check')">
                    <div v-if="isValid">
                        <p class="has-text-centered">Tisztelt Partnerünk! Egy adminisztrátorunk meghívót küldött az Ön számára. Kérjük, csatlakozzon a Sportcsarnok rendszerhez a meghívó elfogadásával. A következő lépésben tudja befejezni a regisztrációját a jelszava beállításával. Köszönjük!</p>
                        <div class="buttons is-centered mt-6">
                            <button class="button is-link is-rounded" :class="{ 'is-loading': mainButtonIsLoading }" @click="accept">Meghívó elfogadása</button>
                            <button class="button is-secondary is-rounded" @click="backToSignIn">Mégse</button>
                        </div>
                    </div>
                </div>
                <div v-else-if="isState('accepted')">
                    <div class="content">
                        <p class="has-text-centered">Kérjük, állítsa be a jelszavát a rendszerhez, ezentúl ennek segítségével tud belépni. A jelszavát a későbbiekben bármikor megváltoztathatja.</p>
                        <p class="has-text-centered">A mentés gombra kattintva véglegesítheti a regisztrációját.</p>
                    </div>
                    <div class="mt-6">
                        <password-reset-form :password-reset-token="passwordResetToken" @success="onPwSuccess" @error="onPwError" />
                    </div>
                </div>
                <div v-else-if="isState('notFound')">
                    <div class="has-text-centered">
                        <p>Ez a meghívó nem található a rendszerünkben. Kérjük, ellenőrizze a linket!</p>
                    </div>
                    <div class="buttons is-centered mt-5">
                        <button class="button is-secondary is-rounded" @click="backToSignIn">Vissza</button>
                    </div>
                </div>
                <div v-else-if="isState('expired')">
                    <div class="has-text-centered">
                        <p>A meghívó lejárt, már nem felhasználható.</p>
                    </div>
                    <div class="buttons is-centered mt-5">
                        <button class="button is-secondary is-rounded" @click="backToSignIn">Vissza</button>
                    </div>
                </div>
                <div v-else-if="isState('error')">
                    <div class="has-text-centered">
                        <p>Hiba történt, kérjük, próbálja meg később.</p>
                    </div>
                    <div class="buttons is-centered mt-5">
                        <button class="button is-secondary is-rounded" @click="backToSignIn">Vissza</button>
                    </div>
                </div>
                <div v-else-if="isState('done')">
                    <div class="has-text-centered">
                        <p>Sikeresen beállította a jelszavát, mostantól használhatja a Sportcsarnok rendszert.</p>
                    </div>
                    <div class="buttons is-centered mt-5">
                        <button class="button is-link is-rounded" @click="backToSignIn">Bejelentkezés</button>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import PasswordResetForm from './password_reset_form.vue'

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

export default {
    props: ['invitationToken'],

    components: {
        PasswordResetForm
    },

    data() {
        return {
            state: 'check',
            mainButtonIsLoading: false,
            isLoading: true,
            isValid: false,
            passwordResetToken: null
        }
    },

    methods: {
        isState(state) {
            return this.state == state
        },

        setState(state) {
            this.state = state
        },

        backToSignIn() {
            this.$router.push({ name: 'signIn' })
        },

        check() {
            axios.post('/u/check_invitation', {
                invitation_token: this.invitationToken
            })
                .then(response => {
                    this.isLoading = false
                    this.isValid = true
                })
                .catch(error => {
                    if (error.response.status == 403) {
                        this.isLoading = false
                        this.setState('expired')
                    }
                    else if (error.response.status == 404) {
                        this.isLoading = false
                        this.setState('notFound')
                    }
                })
        },

        accept() {
            this.mainButtonIsLoading = true
            axios.post('/u/accept_invitation', {
                invitation_token: this.invitationToken
            })
                .then(response => {
                    this.mainButtonIsLoading = false
                    this.passwordResetToken = response.data.token
                    this.setState('accepted')
                    this.mainButtonIsLoading = false
                })
                .catch(error => {
                    this.mainButtonIsLoading = false
                    this.setState('error')
                })
        },

        onPwSuccess() {
            this.setState('done')
        },

        onPwError() {
            this.setState('error')
        },
    },

    mounted() {
        this.check()
    }
}
</script>

<style lang="scss" scoped>
</style>
