<template>
    <form>
        <div class="field">
            <label class="label">Jelszó</label>
            <div class="control">
                <input class="input" name="password" type="password" autocomplete="off" v-model="password">
            </div>
        </div>
        <div class="field">
            <label class="label">Jelszó újra</label>
            <div class="control">
                <input class="input" name="password_confirmation" type="password" autocomplete="off" v-model="passwordConfirmation">
            </div>
        </div>
        <div class="buttons is-centered mt-6">
            <button type="submit" class="button is-link is-rounded" :class="{ 'is-loading': mainButtonIsLoading }" :disabled="!canSave" @click.prevent="setPassword">Mentés</button>
        </div>
    </form>
</template>

<script>
import axios from 'axios'

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

export default {
    props: ['passwordResetToken'],

    data() {
        return {
            mainButtonIsLoading: false,
            password: null,
            passwordConfirmation: null,
        }
    },

    computed: {
        canSave() {
            return this.password && this.password.length > 0 && this.passwordConfirmation && this.passwordConfirmation.length > 0 && this.password == this.passwordConfirmation
        }
    },

    methods: {
        setPassword() {
            if (!this.canSave) {
                return false
            }

            axios.post('/u/reset_password', {
                token: this.passwordResetToken,
                user: {
                    password: this.password,
                    password_confirmation: this.passwordConfirmation
                }
            })
                .then(response => {
                    this.$emit('success')
                })
                .catch(error => {
                    this.$emit('error')
                })
        }
    }
}
</script>

<style>

</style>
