// Styles
import 'scss/login.scss'

import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import App from 'js/login/app.vue'
import SignIn from 'js/login/sign_in.vue'
import ForgotPassword from 'js/login/forgot_password.vue'
import Invitation from 'js/login/invitation.vue'
import PasswordReset from 'js/login/password_reset.vue'

const router = createRouter({
    history: createWebHistory('/sign_in'),

    routes: [
        {
            path: '/',
            name: 'signIn',
            component: SignIn
        },
        {
            path: '/forgot_password',
            name: 'forgotPassword',
            component: ForgotPassword
        },
        {
            path: '/invitation/:invitationToken',
            name: 'invitation',
            component: Invitation,
            props: true
        },
        {
            path: '/password_reset/:passwordResetToken',
            name: 'passwordReset',
            component: PasswordReset,
            props: true
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: { name: 'signIn' }

        }
    ],

    scrollBehavior(to, from, savedPosition) {
        return {
            top: 0,
            behavior: 'smooth'
        }
    }
})

const app = createApp(App)
app.use(router)
app.mount('#login-app')

