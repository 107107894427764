<template>
    <div>
        <div class="sign-in-box box p-6">
            <h1 class="title is-5 mb-6 has-text-centered">Sportcsarnok bejelentkezés</h1>

            <Transition name="slide-left" mode="out-in" @after-enter="onAfterEnter">
                <div v-if="isState('enterLoginName')">
                    <form>
                        <div class="field">
                            <label class="label">E-mail vagy felhasználónév</label>
                            <div class="control">
                                <input class="input" name="username" type="text" autofocus autocomplete="off" v-model="loginName" ref="loginNameInput"><!-- @keyup.enter.prevent="checkLoginName" -->
                            </div>
                        </div>
                        <div class="buttons is-centered mt-5">
                            <button type="submit" class="button is-link is-rounded" :class="{ 'is-loading': mainButtonIsLoading }" :disabled="!loginNameEntered" @click.prevent="checkLoginName">Tovább</button>
                        </div>
                    </form>
                </div>
                <div v-else-if="isState('enterPassword')">
                    <form>
                        <div class="field">
                            <label class="label">Jelszó</label>
                            <div class="control">
                                <input class="input" name="password" type="password" autocomplete="off" v-model="password" ref="pwInput"><!-- @keyup.enter.prevent="signIn" -->
                            </div>
                        </div>
                        <div class="buttons is-centered mt-5">
                            <button type="submit" class="button is-link is-rounded" :class="{ 'is-loading': mainButtonIsLoading }" :disabled="!passwordEntered" @click.prevent="signIn">Bejelentkezés</button>
                            <button class="button is-secondary is-rounded" @click.prevent="retry">Mégse</button>
                        </div>
                    </form>
                </div>
                <div v-else-if="isState('invalidCredentials')">
                    <div class="has-text-centered">
                        A bejelentkezés sikertelen.<br>Nem megfelelő e-mail címet, felhasználónevet vagy jelszót adott meg.
                    </div>
                    <div class="buttons is-centered mt-5">
                        <button class="button is-link is-rounded" @click="retry">Újra</button>
                        <button class="button is-secondary is-rounded" @click="forgotPassword">Elfelejtett jelszó</button>
                    </div>
                </div>
                <div v-else-if="isState('success')">
                    <div class="mb-6 has-text-centered">
                        Sikeres bejelentkezés!
                    </div>
                    <div class="loader loader--centered"></div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

export default {
    data() {
        return {
            loginName: null,
            password: null,
            mainButtonIsLoading: false,
            state: 'enterLoginName'
        }
    },

    computed: {
        loginNameEntered() {
            return this.loginName && this.loginName.length > 2
        },

        passwordEntered() {
            return this.password && this.password.length > 0
        }
    },

    methods: {
        isState(state) {
            return this.state == state
        },

        setState(state) {
            this.state = state
        },

        checkLoginName() {
            if (!this.loginNameEntered) {
                return false
            }
            this.mainButtonIsLoading = true
            setTimeout(() => {
                this.setState('enterPassword')
                this.mainButtonIsLoading = false
            }, 500)
        },

        signIn() {
            if (!this.loginNameEntered || !this.passwordEntered) {
                return false
            }
            this.mainButtonIsLoading = true
            axios.post('/auth.json', {
                login_name: this.loginName,
                password: this.password
            })
                .then(response => {
                    this.setState('success')
                    this.mainButtonIsLoading = false

                    setTimeout(() => {
                        window.location.href = "/redirect"
                    }, 1500)
                })
                .catch(error => {
                    this.setState('invalidCredentials')
                    this.mainButtonIsLoading = false
                })
        },

        retry() {
            this.loginName = null
            this.password = null
            this.setState('enterLoginName')
        },

        forgotPassword() {
            this.$router.push({ name: 'forgotPassword' })
        },

        onAfterEnter() {
            if (this.state == 'enterLoginName') {
                this.$nextTick(() => this.$refs.loginNameInput.focus())
            }
            if (this.state == 'enterPassword') {
                this.$nextTick(() => this.$refs.pwInput.focus())
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
