<template>
    <div class="login-app columns is-centered is-vcentered">
        <div class="column is-half">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
// export default {
// }
</script>

<style lang="scss" scoped>
    .login-app {
        min-height: 100vh;
    }
</style>
