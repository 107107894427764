<template>
    <div>
        <div class="sign-in-box box p-6">
            <h1 class="title is-5 mb-6 has-text-centered">Elfelejtett jelszó</h1>

            <Transition name="slide-left" mode="out-in">
                <div v-if="!isPwResetInitiated">
                    <div class="field">
                        <label class="label">Kérjük, adja meg a Sportcsarnok rendszerhez használt e-mail címét!</label>
                        <div class="control">
                            <input class="input" type="email" autofocus autocomplete="off" v-model="email" @keyup.enter="initiatePasswordReset">
                        </div>
                    </div>
                    <div class="buttons is-centered mt-5">
                        <button class="button is-link is-rounded" :class="{ 'is-loading': mainButtonIsLoading }" :disabled="!emailEntered" @click="initiatePasswordReset">Küldés</button>
                        <button class="button is-secondary is-rounded" @click="backToSignIn">Mégse</button>
                    </div>
                </div>
                <div v-else-if="isPwResetInitiated">
                    <div class="has-text-centered">
                        <p>Köszönjük, kérését fogadtuk.</p>
				        <p>Amennyiben a rendszer talál a megadott e-mail címhez érvényes regisztrációt, e-mailben értesítjük a további lépésekről. Kérjük, ellenőrizze a postafiókját.</p>
                    </div>
                    <!--<div class="buttons is-centered mt-5">
                        <button class="button is-link is-rounded" @click="backToSignIn">Tovább a bejelentkezéshez</button>
                    </div>-->
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

export default {
    data() {
        return {
            email: null,
            mainButtonIsLoading: false,
            isPwResetInitiated: false
        }
    },

    computed: {
        emailEntered() {
            return this.email && this.email.length > 3
        }
    },

    methods: {
        backToSignIn() {
            this.$router.push({ name: 'signIn' })
        },

        initiatePasswordReset() {
            if (!this.emailEntered) {
                return false
            }
            this.mainButtonIsLoading = true
            axios.post('/u/forgot_password', {
                email: this.email
            })
                .then(response => {
                    this.isPwResetInitiated = true
                    this.mainButtonIsLoading = false
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
