<template>
    <div>
        <div class="sign-in-box box p-6">
            <h1 class="title is-5 mb-6 has-text-centered">Új jelszó beállítása</h1>

            <div class="loader loader--centered" v-if="isLoading"></div>

            <Transition name="slide-left" mode="out-in">
                <div v-if="isState('setPassword')">
                    <div class="content">
                        <p class="has-text-centered">Kérjük, adja meg új jelszavát. A sikeres mentést követően az új jelszavával tud bejelentkezni a rendszerbe.</p>
                    </div>
                    <div class="mt-6">
                        <password-reset-form :password-reset-token="passwordResetToken" @success="onPwSuccess" @error="onPwError" />
                    </div>
                </div>
                <div v-else-if="isState('invalid')">
                    <div class="has-text-centered">
                        <p>A jelszó beállítása nem lehetséges. Vagy lejárt a rendelkezésre álló idő, vagy hibás linket használt. Kérjük, próbálja újra!</p>
                    </div>
                    <div class="buttons is-centered mt-5">
                        <button class="button is-secondary is-rounded" @click="backToSignIn">Vissza</button>
                    </div>
                </div>
                <div v-else-if="isState('error')">
                    <div class="has-text-centered">
                        <p>Hiba történt, kérjük, próbálja meg később.</p>
                    </div>
                    <div class="buttons is-centered mt-5">
                        <button class="button is-secondary is-rounded" @click="backToSignIn">Vissza</button>
                    </div>
                </div>
                <div v-else-if="isState('done')">
                    <div class="has-text-centered">
                        <p>Sikeresen beállította új jelszavát! Kérjük, jelentkezzen be a rendszerbe!</p>
                    </div>
                    <div class="buttons is-centered mt-5">
                        <button class="button is-link is-rounded" @click="backToSignIn">Bejelentkezés</button>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import PasswordResetForm from './password_reset_form.vue'

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

export default {
    props: ['passwordResetToken'],

    components: {
        PasswordResetForm
    },

    data() {
        return {
            state: null,
            isLoading: true
        }
    },

    methods: {
        isState(state) {
            return this.state == state
        },

        setState(state) {
            this.state = state
        },

        backToSignIn() {
            this.$router.push({ name: 'signIn' })
        },

        check() {
            axios.post('/u/check_password_reset', {
                token: this.passwordResetToken
            })
                .then(response => {
                    this.isLoading = false
                    this.setState('setPassword')
                })
                .catch(error => {
                    this.isLoading = false
                    this.setState('invalid')
                })
        },

        onPwSuccess() {
            this.setState('done')
        },

        onPwError() {
            this.setState('error')
        },
    },

    mounted() {
        this.check()
    }
}
</script>

<style lang="scss" scoped>
</style>
